/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { Messages } from '@/store/messages/types';

const namespace: string = 'messages';

export default class MessagesPage extends Vue {
	@State('messages') messages: Messages | undefined;
	@Action('fetchTodayMessages', {namespace}) fetchTodayMessages: any;
	@Action('fetchHistoryMessages', {namespace}) fetchHistoryMessages: any;
	@Getter('getTodayMessages', {namespace}) getTodayMessages: any;
	@Getter('getHistoryMessages', {namespace}) getHistoryMessages: any;
	@Mutation('setLastMessage', {namespace}) setLastMessage: any;


	toResponse(message: any) {
		this.setLastMessage(message);
		this.$router.push({ name: 'messages-reply' }).then(r => r);
	}
	toBack() {
		this.$router.push({ name: 'messages' }).then(r => r);
	}
	toMain() {
		this.$router.push({ name: '/' }).then(r => r);
	}
	mounted() {
		this.fetchTodayMessages();
		this.fetchHistoryMessages();
	}
}
